export const environment = {
  name: 'mantis',
  production: true,
  enablePendo: false,
  enableDatadog: false,
  enableServiceWorker: true,
  enableGoogleAnalytics: false,
  enableFullStory: false,
  applicationUrl: 'https://qa.mims.rewaatech.com',
  webSocketUrl: 'wss://cbc2xkj24j.execute-api.us-east-1.amazonaws.com/qa',
  fullViewClientId: '',
  sallaAppClientId: 'b8c141e5521c157a6855ce2e6426e165',
  zidAppClientId: '348',
  bonatAppClientId: '',
  userPoolId: 'us-east-1_665Pnr55q',
  clientId: 'l6jcbph4jkqmg9cp1fjl81dru',
  bonatServiceToken: '',
  bonatRedirectUrl: '',
  cognitoAuthUrl: '',
  s3InventoryBucket:
    'https://s3.amazonaws.com/qa-mims-service-any-item-image/inventory/products/',
  defaultImage: 'default.png',
  enableHttp: true,
  enableIntercom: false,
  tapPublicKey: 'pk_test_jMgIW1ilDhXYSNPQ6BKob8aV',
  s3Bucket: 'https://s3.ap-south-1.amazonaws.com/qa.mims.rewaatech.com/',
  offline_intervals: { product: 2160000, customer: 2160000 },
  offline_config: {
    invoice_limit: { android: 10, others: 15 },
  },
  enableUserGuiding: false,
  enableUserPilot: false,
  launchDarklyClientSideId: '6359237ce4483b1158727b7b',
  mockLaunchDarkly: false,
  enableAutomationTestFF: false,
  automationTestFFAPIKey: '123',
  pluginUrl: 'http://localhost:5000',
  accountingApiPath: '/api/accounting',
  expenseApiPath: '/api/expense-service',
  customFieldApiPath: '/api/custom-field-service',
  firebaseConfig: {
    apiKey: 'AIzaSyDpqm3hlLPwJ5mZmyX_GuGfth1lRWB1fHo',
    authDomain: 'rewaa-test.firebaseapp.com',
    projectId: 'rewaa-test',
    storageBucket: 'rewaa-test.appspot.com',
    messagingSenderId: '181952186125',
    appId: '1:181952186125:web:d9530938a18589a82f9244',
    measurementId: 'G-6XEQD0RRJV',
  },
  intercomAppId: 'sg0ry903',
  enableFirestore: true,
  allowAliasedEmails: true,
  reportDataLimit: 10000,
  reportingApiUrl: 'https://reporting.qa.mims.rewaatech.com/cubejs-api/v1',
  appVersion: require('../../package.json').version,
  nearpayEnvironment: 'sandbox',
  launchDarklyBaseUrl: undefined,
};
