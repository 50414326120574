import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CommonEnums,
  CustomerEnums,
  CustomerTypes,
  InvoiceEnums,
  InvoiceSnapshotTypes,
  InvoiceTypes,
  QuotationTypes,
  RegisterTypes,
  ZatcaConstants,
  ZatcaTypes,
} from '@rewaa-team/pos-sdk';
import { ExportServiceTypes } from '@rewaa-team/rewaa-api-types';
import {
  ProductTypes,
  RewaaApiMeta,
  RewaaBaseApiResponse,
  RewaaOffsetPaginatedResponse,
  RewaaPaginatedResponse,
  TaxType,
} from '@rewaa-team/types';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { Layout } from '../../internal-apps/pos/model/Layout';
import { Setting } from '../../internal-apps/pos/model/Setting';
import { PaginatedLayoutCategoryProducts } from '../../internal-apps/pos/shared/types/common.types';
import { Product } from '../../inventory/model/product';
import { ProductVariant } from '../../inventory/model/product-variant';
import {
  GetVariantByIdForPOSParams,
  GetVariantByIdForPOSResponse,
} from '../../inventory/products/services/types/product.service.types';
import {
  Register,
  RegisterClosingLog,
  VerifyRegisterAccessResponse,
} from '../../point-of-sale/types/register.type';
import { CompleteSaleInput } from '../../point-of-sale/types/sell.type';
import {
  Template,
  TemplateType,
} from '../../point-of-sale/types/template.type';
import { GetZatcaLogsParams } from '../../zatca/zatca.types';
import { RegisterReport } from '../components/register-report-template/register-report.type';
import { BYPASS_NORMAL } from '../interceptors/http-error.interceptor';
import {
  PaginationQuery,
  PaginationQueryQuickMenuDialog,
} from '../model/PaginationQuery';
import { ExportRequest } from '../types/pos-api.types';
import { GetReturnAndSellInvoicesInterface } from './types/types';

@Injectable()
export class PosApiService {
  private ApiUrl = '/api';

  private PosServiceUrl = '/api/pos-service';

  private enigmaUrl = '/api/enigma';

  constructor(private readonly http: HttpClient) {}

  // #region Registers APIs

  getRegisterById(id: number): Observable<Register> {
    return this.http.get<Register>(`${this.ApiUrl}/pos/registers/${id}`);
  }

  getAllPOSLocationRegisters(): Observable<
    RegisterTypes.StockLocationRegisterResponse[]
  > {
    return this.http.get<RegisterTypes.StockLocationRegisterResponse[]>(
      `${this.PosServiceUrl}/registers/locations`,
    );
  }

  getAllPOSLocationRegistersV2(): Observable<
    RewaaBaseApiResponse<RegisterTypes.StockLocationRegisterResponse[], never>
  > {
    return this.http.get<
      RewaaBaseApiResponse<RegisterTypes.StockLocationRegisterResponse[], never>
    >(`${this.enigmaUrl}/registers/locations`);
  }

  openRegister(
    id: number,
    data: { userId: number; amount: number; deviceIdentifier?: string },
  ): Observable<Register> {
    return this.http.put<Register>(`${this.ApiUrl}/pos/registers/${id}/open`, {
      userId: data.userId,
      openingAmount: data.amount,
      deviceIdentifier: data.deviceIdentifier,
    });
  }

  closeRegister(
    id: number,
    data: {
      userId: number;
      closingLogs: RegisterClosingLog[];
      registerForceClose?: boolean;
    },
  ): Observable<Register | { message: string } | RegisterReport> {
    return this.http.put<Register>(
      `${this.ApiUrl}/pos/registers/${id}/close`,
      data,
    );
  }

  registerSellingSession(
    registerId: number,
    payload: { token: string; forceBooking: boolean; userId: number },
  ): Observable<boolean> {
    return this.http.put<boolean>(
      `${this.PosServiceUrl}/registers/${registerId}/selling-session`,
      payload,
    );
  }

  deleteRegisterSellingSession(
    userId: number,
    registerId: number,
  ): Observable<boolean> {
    return this.http.delete<boolean>(
      `${this.PosServiceUrl}/registers/${registerId}/selling-session`,
      { body: { userId } },
    );
  }

  getRegisterTotalBalance(registerId: number): Observable<number> {
    return this.http.get<number>(
      `${this.ApiUrl}/pos/registers/${registerId}/balance`,
    );
  }

  patchRegisterCash(
    registerId: number,
    payload: unknown,
  ): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(
      `${this.ApiUrl}/pos/registers/${registerId}/cash`,
      payload,
    );
  }

  verifyRegisterAccess(
    registerId: number,
  ): Observable<VerifyRegisterAccessResponse> {
    return this.http.get<VerifyRegisterAccessResponse>(
      `${this.ApiUrl}/pos/registers/${registerId}/auth`,
    );
  }

  // #endregion

  // #region Templates APIs

  getTemplates(): Observable<Template[]> {
    return this.http.get<Template[]>(`${this.ApiUrl}/pos/templates`);
  }

  getTemplateTypes(
    isActive = true,
    withSettings = false,
    withCategories = false,
  ): Observable<TemplateType[]> {
    const params = new HttpParams()
      .set('isActive', isActive)
      .set('settings', withSettings)
      .set('categories', withCategories);

    return this.http.get<TemplateType[]>(
      `${this.ApiUrl}/pos/template-types/templates`,
      {
        params,
      },
    );
  }

  getTemplateById(id: number): Observable<Template> {
    return this.http.get<Template>(`${this.ApiUrl}/pos/templates/${id}`);
  }

  addTemplate(template: Template): Observable<Template> {
    return this.http.post<Template>(`${this.ApiUrl}/pos/templates`, template);
  }

  updateTemplateTypesStatus(
    templateTypes: TemplateType[],
  ): Observable<TemplateType[]> {
    return this.http.put<TemplateType[]>(
      `${this.ApiUrl}/pos/templates-types/bulk-update`,
      templateTypes,
    );
  }

  updateTemplate(template: Template): Observable<Template> {
    return this.http.put<Template>(
      `${this.ApiUrl}/pos/templates/${template.id}`,
      template,
    );
  }

  // #endregion

  // #region Settings APIs

  getSettings(): Observable<Setting[]> {
    return this.http.get<Setting[]>(`${this.ApiUrl}/pos/settings`);
  }

  getNextSequences(): Observable<InvoiceTypes.GetNextSequences> {
    return this.http.get<InvoiceTypes.GetNextSequences>(
      `${this.enigmaUrl}/settings/sequences`,
    );
  }

  // #endregion

  // #region Customer APIs
  public getUnpaidCustomerInvoices(
    customerId: number,
  ): Observable<
    RewaaBaseApiResponse<CustomerTypes.UnpaidInvoiceOutput[], never>
  > {
    return this.http.get<
      RewaaBaseApiResponse<CustomerTypes.UnpaidInvoiceOutput[], never>
    >(`${this.enigmaUrl}/customers/${customerId}/unpaid-invoices`);
  }

  public receiveDebit(
    data: CustomerTypes.ReceiveDebitInput,
  ): Observable<RewaaBaseApiResponse<CustomerTypes.ReceiveDebitOutput, never>> {
    return this.http.post<
      RewaaBaseApiResponse<CustomerTypes.ReceiveDebitOutput, never>
    >(`${this.enigmaUrl}/customers/receive-debit`, data);
  }

  public getCustomerAutoCompleteSuggestions(
    field: CustomerEnums.AutoCompleteField,
    query: string,
    offset = 0,
    limit = 10,
  ): Observable<
    RewaaPaginatedResponse<
      CustomerTypes.CustomerAutoCompleteOutput,
      RewaaOffsetPaginatedResponse
    >
  > {
    const params = new HttpParams()
      .set('field', field)
      .set('query', query)
      .set('offset', offset)
      .set('limit', limit);
    return this.http.get<
      RewaaPaginatedResponse<
        CustomerTypes.CustomerAutoCompleteOutput,
        RewaaOffsetPaginatedResponse
      >
    >(`${this.enigmaUrl}/customers/auto-complete`, {
      params,
    });
  }

  // #endregion

  completeSale(
    input: CompleteSaleInput,
  ): Observable<
    RewaaBaseApiResponse<InvoiceTypes.InvoiceCreationOutput, never>
  > {
    return this.http.post<
      RewaaBaseApiResponse<InvoiceTypes.InvoiceCreationOutput, never>
    >(`${this.enigmaUrl}/pos/complete-sale`, input);
  }

  sell(
    input: InvoiceTypes.InvoiceCreationPayload,
  ): Observable<
    RewaaBaseApiResponse<InvoiceTypes.InvoiceCreationOutput, never>
  > {
    return this.http.post<
      RewaaBaseApiResponse<InvoiceTypes.InvoiceCreationOutput, never>
    >(`${this.enigmaUrl}/pos/sell`, input);
  }
  // #region Layout APIs

  getLayoutDetailV2(id: number): Observable<Layout> {
    return this.http.get<Layout>(`${this.ApiUrl}/v2/pos/layouts/${id}`);
  }

  getLayoutCategoryProducts(
    id: number,
    stockLocationId: number,
    offset = 0,
    limit = 10,
  ): Observable<PaginatedLayoutCategoryProducts> {
    let params = new HttpParams().set('taxMode', TaxType.Exclusive);
    if (offset != null) {
      params = params.set('offset', offset);
    }
    if (limit != null) {
      params = params.set('limit', limit);
    }
    return this.http.get<PaginatedLayoutCategoryProducts>(
      `${this.ApiUrl}/pos/layouts/category/${id}/${stockLocationId}/products`,
      { params },
    );
  }

  updateLayout(layout: Partial<Layout>): Observable<Layout> {
    return this.http.put<Layout>(
      `${this.ApiUrl}/pos/layouts/${layout.id}`,
      layout,
    );
  }

  // #endregion

  // #region offline APIs

  syncOfflineInvoiceNumbers(
    data: InvoiceTypes.SyncInvoiceNumbersPayload,
  ): Observable<unknown> {
    return this.http.post(`${this.enigmaUrl}/invoices/numbers/sync`, data);
  }

  syncOfflineInvoices(
    data: InvoiceTypes.InvoiceCreationPayload[],
  ): Observable<RewaaBaseApiResponse<boolean[], never>> {
    return this.http.post<RewaaBaseApiResponse<boolean[], never>>(
      `${this.enigmaUrl}/invoices/sync`,
      data,
    );
  }

  // #endregion
  // #region get variants

  searchVariantsForSpecificLocation(
    pageQuery: PaginationQuery,
    stockLocationId: number,
  ): Observable<ProductVariant[]> {
    const { query, limit, offset } = {
      ...pageQuery,
      query: pageQuery.query,
    };
    let params = new HttpParams()
      .set('taxMode', TaxType.Exclusive)
      .set('query', query);
    if (offset != null) {
      params = params.set('offset', offset);
    }
    if (limit != null) {
      params = params.set('limit', limit);
    }
    return this.http.get<ProductVariant[]>(
      `${this.ApiUrl}/pos/locations/${stockLocationId}/variants`,
      { params },
    );
  }

  getVariantByScannerCode(
    code: string,
    stockLocationId: number,
  ): Observable<ProductVariant> {
    const encodedCode = encodeURIComponent(code);
    const params = new HttpParams().set('taxMode', TaxType.Exclusive);
    return this.http.get<ProductVariant>(
      `${this.ApiUrl}/pos/locations/${stockLocationId}/scanner/${encodedCode}`,
      { params },
    );
  }

  getPaginatedProductsQuicMenuDialog(
    query: PaginationQueryQuickMenuDialog,
  ): Observable<{ result: Product[]; total: number }> {
    const productQueryParams = new HttpParams()
      .set('search', query.search || '')
      .set('offset', query.offset || '0')
      .set('limit', query.limit || '10')
      .set('sortDirection', query.sortDirection.toString())
      .set('columns', query.columns.toString());
    const url = `${this.ApiUrl}/products-v3`;
    return this.http.get<{ result: Product[]; total: number }>(url, {
      params: productQueryParams,
      context: new HttpContext().set(BYPASS_NORMAL, true),
    });
  }

  getVariantByIdForPOS(
    payload: GetVariantByIdForPOSParams,
  ): Observable<GetVariantByIdForPOSResponse> {
    const { variantId } = payload;
    const path = `/pos/variants/${variantId}`;

    let httpPrams = new HttpParams()
      .set('locationId', payload.locationId)
      .set('variantId', payload.variantId)
      .set('offset', payload.offset)
      .set('limit', payload.limit);

    if (payload.orderBy) {
      httpPrams = httpPrams.append('orderBy', payload.orderBy);
      httpPrams = httpPrams.append('order', payload.order);
    }

    if (payload.filter) {
      httpPrams = httpPrams.append('filter', payload.filter);
    }

    const options = { params: httpPrams };

    return this.http.get<GetVariantByIdForPOSResponse>(
      `${this.ApiUrl}${path}`,
      options,
    );
  }

  // #end region

  // Customers

  public getCustomers(
    pageQuery: CustomerTypes.SearchCustomerQueryParam,
  ): Observable<
    RewaaPaginatedResponse<
      CustomerTypes.GetCustomerOutput,
      RewaaOffsetPaginatedResponse
    >
  > {
    const { offset, limit, query, ids } = pageQuery;
    const params = new HttpParams()
      .set('query', query)
      .set('limit', limit)
      .set('offset', offset)
      .set('ids', ids || '');

    return this.http.get<
      RewaaPaginatedResponse<
        CustomerTypes.GetCustomerOutput,
        RewaaOffsetPaginatedResponse
      >
    >(`${this.enigmaUrl}/customers`, { params });
  }

  public getCustomersForPos(
    pageQuery: CustomerTypes.SearchCustomerQueryParam,
  ): Observable<
    RewaaPaginatedResponse<
      CustomerTypes.GetCustomerOutput,
      RewaaOffsetPaginatedResponse
    >
  > {
    const { offset, limit, query } = pageQuery;
    const params = new HttpParams()
      .set('query', query)
      .set('limit', limit)
      .set('offset', offset);
    return this.http.get<
      RewaaPaginatedResponse<
        CustomerTypes.GetCustomerOutput,
        RewaaOffsetPaginatedResponse
      >
    >(`${this.enigmaUrl}/customers/pos`, { params });
  }

  public getCustomerById(
    id: number,
  ): Observable<
    RewaaBaseApiResponse<CustomerTypes.GetCustomerOutput, RewaaApiMeta>
  > {
    return this.http.get<
      RewaaBaseApiResponse<CustomerTypes.GetCustomerOutput, RewaaApiMeta>
    >(`${this.enigmaUrl}/customers/${id}`);
  }

  public updateCustomer(
    customer: CustomerTypes.PatchCustomerInput,
  ): Observable<
    RewaaBaseApiResponse<CustomerTypes.GetCustomerOutput, RewaaApiMeta>
  > {
    customer.address.source = CommonEnums.SourceConstant.POS;
    return this.http.patch<
      RewaaBaseApiResponse<CustomerTypes.GetCustomerOutput, RewaaApiMeta>
    >(`${this.enigmaUrl}/customers/${customer.id}`, customer);
  }

  public createCustomer(
    customer: CustomerTypes.PostCustomerInput,
  ): Observable<
    RewaaBaseApiResponse<CustomerTypes.GetCustomerOutput, RewaaApiMeta>
  > {
    customer.address.source = CommonEnums.SourceConstant.POS;
    return this.http.post<
      RewaaBaseApiResponse<CustomerTypes.GetCustomerOutput, RewaaApiMeta>
    >(`${this.enigmaUrl}/customers`, customer);
  }

  public getCustomerByCode(
    code: string,
  ): Observable<RewaaBaseApiResponse<number | null, RewaaApiMeta>> {
    const params = new HttpParams().set('code', code);
    return this.http.get<RewaaBaseApiResponse<number | null, RewaaApiMeta>>(
      `${this.enigmaUrl}/customers/validate`,
      { params },
    );
  }

  public getCustomerByMobile(
    mobileNumber: string,
  ): Observable<RewaaBaseApiResponse<number | null, RewaaApiMeta>> {
    const params = new HttpParams().set('mobileNumber', mobileNumber);
    return this.http.get<RewaaBaseApiResponse<number | null, RewaaApiMeta>>(
      `${this.enigmaUrl}/customers/validate`,
      { params },
    );
  }

  public autoGenerateCustomerCode(): Observable<
    RewaaBaseApiResponse<string, RewaaApiMeta>
  > {
    return this.http.get<RewaaBaseApiResponse<string, RewaaApiMeta>>(
      `${this.enigmaUrl}/customers/next-code`,
    );
  }

  public deleteCustomer(
    id: number,
  ): Observable<RewaaBaseApiResponse<boolean, RewaaApiMeta>> {
    return this.http.delete<RewaaBaseApiResponse<boolean, RewaaApiMeta>>(
      `${this.enigmaUrl}/customers/${id}`,
    );
  }

  // #region deprecated API(s) - Move to relevant service/sdk
  public getSalesmenList(
    locationId: number,
  ): Observable<InvoiceTypes.Salesman[]> {
    const params = new HttpParams().set('locationId', locationId);
    return this.http.get<InvoiceTypes.Salesman[]>(
      `${this.ApiUrl}/mims-extended/settings/salesman`,
      {
        params,
      },
    );
  }

  // end region

  public generateRegisterShiftReport(
    shiftId: number,
  ): Observable<
    RewaaBaseApiResponse<
      RegisterTypes.RegisterShiftReportResponse | undefined,
      RewaaApiMeta
    >
  > {
    return this.http.get<
      RewaaBaseApiResponse<
        RegisterTypes.RegisterShiftReportResponse | undefined,
        RewaaApiMeta
      >
    >(`${this.enigmaUrl}/registers/shifts/${shiftId}/reports`);
  }

  public getRegisterShiftReport(
    shiftId: number,
  ): Observable<
    RewaaBaseApiResponse<
      RegisterTypes.RegisterShiftReportResponse | undefined,
      RewaaApiMeta
    >
  > {
    return this.http.get<
      RewaaBaseApiResponse<
        RegisterTypes.RegisterShiftReportResponse | undefined,
        RewaaApiMeta
      >
    >(`${this.enigmaUrl}/registers/shifts/${shiftId}/reports/v2`);
  }

  public getCashManagementShiftSummary(
    shiftId: number,
  ): Observable<
    RewaaBaseApiResponse<
      RegisterTypes.CashManagementShiftSummaryResponse,
      RewaaApiMeta
    >
  > {
    return this.http.get<
      RewaaBaseApiResponse<
        RegisterTypes.CashManagementShiftSummaryResponse,
        RewaaApiMeta
      >
    >(`${this.enigmaUrl}/registers/shifts/${shiftId}/summary`);
  }

  public getShiftPaymentsReportSummary(
    shiftId: number,
  ): Observable<
    RewaaBaseApiResponse<
      RegisterTypes.ShiftPaymentsReportSummaryResponse[],
      RewaaApiMeta
    >
  > {
    return this.http.get<
      RewaaBaseApiResponse<
        RegisterTypes.ShiftPaymentsReportSummaryResponse[],
        RewaaApiMeta
      >
    >(`${this.enigmaUrl}/registers/shifts/${shiftId}/summary/payments`);
  }

  getCashManagementShiftDetail(
    shiftId: number,
  ): Observable<
    RewaaBaseApiResponse<RegisterTypes.CashManagementShiftDetailResponse, never>
  > {
    return this.http.get<
      RewaaBaseApiResponse<
        RegisterTypes.CashManagementShiftDetailResponse,
        never
      >
    >(`${this.enigmaUrl}/registers/shifts/${shiftId}/detail`);
  }

  getSellAndReturnInvoicesByInvoiceId(
    invoiceId: number,
  ): Observable<
    RewaaBaseApiResponse<GetReturnAndSellInvoicesInterface, RewaaApiMeta>
  > {
    const params = new HttpParams().set('invoiceId', invoiceId.toString());
    return this.http.get<
      RewaaBaseApiResponse<GetReturnAndSellInvoicesInterface, RewaaApiMeta>
    >(`${this.enigmaUrl}/invoices/return?invoiceId=${invoiceId}`, { params });
  }

  return(
    input: InvoiceTypes.InvoiceReturnPayload,
  ): Observable<
    RewaaBaseApiResponse<InvoiceTypes.InvoiceCreationOutput, RewaaApiMeta>
  > {
    return this.http.post<
      RewaaBaseApiResponse<InvoiceTypes.InvoiceCreationOutput, RewaaApiMeta>
    >(`${this.enigmaUrl}/pos/return`, input);
  }

  getInvoices(
    query: InvoiceTypes.InvoiceListParams,
  ): Observable<
    RewaaPaginatedResponse<
      InvoiceTypes.InvoiceListOutput,
      RewaaOffsetPaginatedResponse
    >
  > {
    const queryParams = new HttpParams()
      .set('invoiceNumber', query.invoiceNumber || '')
      .set('customer', query.customer || '')
      .set('variantName', query.variantName || '')
      .set('variantSku', query.variantSku || '')
      .set('serialNumber', query.serialNumber || '')
      .set('fromDate', query.fromDate ? query.fromDate.toISOString() : '')
      .set('toDate', query.toDate ? query.toDate.toISOString() : '')
      .set('paymentIds', query.paymentIds?.join(',') || '')
      .set('locationIds', query.locationIds?.join(',') || '')
      .set('type', query.type || '')
      .set('source', CommonEnums.SourceConstant.POS)
      .set('offset', query.offset || 0)
      .set('limit', query.limit || 10)
      .set('status', InvoiceEnums.InvoiceStatusConstant.Completed);

    return this.http.get<
      RewaaPaginatedResponse<
        InvoiceTypes.InvoiceListOutput,
        RewaaOffsetPaginatedResponse
      >
    >(`${this.enigmaUrl}/invoices`, {
      params: queryParams,
    });
  }

  getInvoiceSnapshot(
    query: InvoiceTypes.InvoiceSnapshotParams,
  ): Observable<
    RewaaBaseApiResponse<InvoiceSnapshotTypes.InvoiceSnapshot, never>
  > {
    const queryParams = new HttpParams()
      .set('invoiceId', query.invoiceId || '')
      .set('invoiceNumber', query.invoiceNumber || '');
    return this.http.get<
      RewaaBaseApiResponse<InvoiceSnapshotTypes.InvoiceSnapshot, never>
    >(`${this.enigmaUrl}/invoices/snapshot`, {
      params: queryParams,
    });
  }

  createQuotation(
    payload: QuotationTypes.CreateQuotationInput,
  ): Observable<RewaaBaseApiResponse<QuotationTypes.Snapshot, never>> {
    return this.http.post<RewaaBaseApiResponse<QuotationTypes.Snapshot, never>>(
      `${this.enigmaUrl}/quotations`,
      payload,
    );
  }

  getQuotations(
    filters: QuotationTypes.SearchQuotationsQueryParams,
  ): Observable<
    RewaaPaginatedResponse<
      QuotationTypes.GetQuotationListItem,
      RewaaOffsetPaginatedResponse
    >
  > {
    return this.http.post<
      RewaaPaginatedResponse<
        QuotationTypes.GetQuotationListItem,
        RewaaOffsetPaginatedResponse
      >
    >(`${this.enigmaUrl}/quotations/list`, filters);
  }

  getQuotation(
    id: number,
  ): Observable<
    RewaaBaseApiResponse<QuotationTypes.GetQuotationResponse, never>
  > {
    return this.http.get<
      RewaaBaseApiResponse<QuotationTypes.GetQuotationResponse, never>
    >(`${this.enigmaUrl}/quotations/${id}`);
  }

  getQuotationListFilterData(): Observable<
    RewaaBaseApiResponse<QuotationTypes.GetQuotationListFilterData, never>
  > {
    return this.http.get<
      RewaaBaseApiResponse<QuotationTypes.GetQuotationListFilterData, never>
    >(`${this.enigmaUrl}/quotations/filters/data`);
  }

  getLocationVariants(
    ids: number[],
    locationId: number,
  ): Observable<ProductTypes.LocationProductVariant[]> {
    const params = new HttpParams().set('ids', ids.join(','));
    return this.http.get<ProductTypes.LocationProductVariant[]>(
      `${this.ApiUrl}/mims-extended/variants/location/${locationId}`,
      { params },
    );
  }

  getOrderNumber(
    locationId: number,
  ): Observable<
    RewaaBaseApiResponse<InvoiceTypes.NextOrderNumberResponse, never>
  > {
    return this.http.get<
      RewaaBaseApiResponse<InvoiceTypes.NextOrderNumberResponse, never>
    >(`${this.enigmaUrl}/pos/locations/${locationId}/next-order-number`);
  }

  addRegister(
    register: RegisterTypes.CreateRegisterPayload,
  ): Observable<
    RewaaBaseApiResponse<RegisterTypes.CreateRegisterResponse, never>
  > {
    return this.http.post<
      RewaaBaseApiResponse<RegisterTypes.CreateRegisterResponse, never>
    >(`${this.enigmaUrl}/registers`, register);
  }

  updateRegister(
    registerId: number,
    payload: RegisterTypes.RegisterUpdatePayload,
  ): Observable<Register> {
    return this.http.put<Register>(
      `${this.enigmaUrl}/registers/${registerId}`,
      payload,
    );
  }

  deleteRegister(
    id: number,
  ): Observable<
    RewaaBaseApiResponse<RegisterTypes.RegisterDeleteResponse, never>
  > {
    return this.http.delete<
      RewaaBaseApiResponse<RegisterTypes.RegisterDeleteResponse, never>
    >(`${this.enigmaUrl}/registers/${id}`);
  }

  getLocationRegisters(
    stockLocationId: number,
  ): Observable<
    RewaaBaseApiResponse<RegisterTypes.GetLocationRegisterResponse[], never>
  > {
    return this.http.get<
      RewaaBaseApiResponse<RegisterTypes.GetLocationRegisterResponse[], never>
    >(`${this.enigmaUrl}/registers/locations/${stockLocationId}`);
  }

  createExportRequest(
    payload: ExportRequest,
  ): Observable<
    RewaaBaseApiResponse<ExportServiceTypes.ExportRequestResponse, never>
  > {
    return this.http.post<
      RewaaBaseApiResponse<ExportServiceTypes.ExportRequestResponse, never>
    >(`${this.enigmaUrl}/export`, payload);
  }

  deactivateRegister(
    id: number,
  ): Observable<
    RewaaBaseApiResponse<RegisterTypes.RegisterDeactivateResponse, never>
  > {
    return this.http.put<
      RewaaBaseApiResponse<RegisterTypes.RegisterDeactivateResponse, never>
    >(`${this.enigmaUrl}/registers/${id}/deactivate`, {});
  }

  activateRegister(
    id: number,
  ): Observable<
    RewaaBaseApiResponse<RegisterTypes.RegisterActivateResponse, never>
  > {
    return this.http.put<
      RewaaBaseApiResponse<RegisterTypes.RegisterActivateResponse, never>
    >(`${this.enigmaUrl}/registers/${id}/activate`, {});
  }

  getRegisterByIdV2(
    id: number,
  ): Observable<
    RewaaBaseApiResponse<RegisterTypes.GetRegisterResponse, never>
  > {
    return this.http.get<
      RewaaBaseApiResponse<RegisterTypes.GetRegisterResponse, never>
    >(`${this.enigmaUrl}/registers/${id}`);
  }

  openRegisterV2(
    id: number,
    payload: RegisterTypes.OpenRegisterPayload,
  ): Observable<
    RewaaBaseApiResponse<RegisterTypes.OpenRegisterResponse, never>
  > {
    return this.http.patch<
      RewaaBaseApiResponse<RegisterTypes.OpenRegisterResponse, never>
    >(`${this.enigmaUrl}/registers/${id}/open`, payload);
  }

  getInvoiceAutoCompleteSuggestions(
    field: InvoiceEnums.AutoCompleteKey,
    value: string,
    offset = 0,
    limit = 10,
  ): Observable<
    RewaaPaginatedResponse<
      InvoiceTypes.AutoCompleteSuggestion,
      RewaaOffsetPaginatedResponse
    >
  > {
    const params = new HttpParams()
      .set('key', field)
      .set('value', value)
      .set('offset', offset)
      .set('limit', limit);
    return this.http.get<
      RewaaPaginatedResponse<
        InvoiceTypes.AutoCompleteSuggestion,
        RewaaOffsetPaginatedResponse
      >
    >(`${this.enigmaUrl}/invoices/auto-complete`, {
      params,
    });
  }

  getOfflineInvoicesSyncCount(
    shiftIds: number[],
  ): Observable<
    RewaaBaseApiResponse<
      RegisterTypes.GetOfflineInvoicesSyncCountResponse,
      never
    >
  > {
    const params = new HttpParams().set('shiftIds', shiftIds.join(','));
    return this.http.get<
      RewaaBaseApiResponse<
        RegisterTypes.GetOfflineInvoicesSyncCountResponse,
        never
      >
    >(`${this.enigmaUrl}/registers/shifts/offline-invoices-count`, { params });
  }

  setDefaultRegister(
    id: number,
  ): Observable<
    RewaaBaseApiResponse<RegisterTypes.SetDefaultRegisterResponse, never>
  > {
    return this.http.patch<
      RewaaBaseApiResponse<RegisterTypes.SetDefaultRegisterResponse, never>
    >(`${this.enigmaUrl}/registers/${id}/default`, {});
  }

  closeRegisterV2(
    id: number,
    payload: RegisterTypes.CloseRegisterPayload,
  ): Observable<
    RewaaBaseApiResponse<RegisterTypes.RegisterShiftReportResponse, never>
  > {
    return this.http.patch<
      RewaaBaseApiResponse<RegisterTypes.RegisterShiftReportResponse, never>
    >(`${this.enigmaUrl}/registers/${id}/close`, payload);
  }

  getZatcaLogs(
    input: GetZatcaLogsParams,
  ): Observable<
    RewaaPaginatedResponse<
      ZatcaTypes.IGetZatcaLogOutput,
      RewaaOffsetPaginatedResponse
    >
  > {
    const {
      offset = 0,
      limit = 5,
      search = '',
      toDate,
      filterBy,
      order,
      orderBy,
    } = input;

    let httpParams = new HttpParams()
      .set('offset', offset)
      .set('limit', limit)
      .set('search', search);

    Object.entries(filterBy).forEach(
      (filter: [string, { action: string; value: string } | string]) => {
        const [key, filterValue] = filter;
        if (
          typeof filterValue === 'object' &&
          Object.keys(filterValue).length
        ) {
          httpParams = httpParams.append('action', filterValue.action);
          httpParams = httpParams.append('status', filterValue.value);
        } else if (
          typeof filterValue === 'string' ||
          typeof filterValue === 'number'
        ) {
          httpParams = httpParams.append(key, filterValue);
        }
      },
    );

    if (orderBy) {
      httpParams = httpParams.append(
        'orderBy',
        orderBy ? ZatcaConstants.ZatcaLogOrderByFieldConstant.LogId : undefined,
      );
      httpParams = httpParams.append(
        'orderDirection',
        order === 1 ? 'ASC' : 'DESC',
      );
    }

    if (toDate) {
      const midnight = DateTime.fromJSDate(toDate).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      }); // set the time to midnight
      const toDateString = midnight.toUTC().toISO();
      httpParams = httpParams.set('toDate', toDateString);
      httpParams = httpParams.set('fromDate', toDateString);
    }

    const options = {
      params: httpParams,
    };
    return this.http.get<
      RewaaPaginatedResponse<
        ZatcaTypes.IGetZatcaLogOutput,
        RewaaOffsetPaginatedResponse
      >
    >(`${this.enigmaUrl}/zatca/logs`, options);
  }

  getRegisterTotalBalanceV2(
    shiftId: number,
  ): Observable<
    RewaaBaseApiResponse<RegisterTypes.RegisterShiftGetBalanceResponse, never>
  > {
    return this.http.get<
      RewaaBaseApiResponse<RegisterTypes.RegisterShiftGetBalanceResponse, never>
    >(`${this.enigmaUrl}/registers/shifts/${shiftId}/balance`);
  }

  addWithdrawRegisterCash(
    shiftId: number,
    payload: RegisterTypes.AddWithdrawCashPayload,
  ): Observable<
    RewaaBaseApiResponse<RegisterTypes.AddWithdrawCashResponse, never>
  > {
    return this.http.put<
      RewaaBaseApiResponse<RegisterTypes.AddWithdrawCashResponse, never>
    >(`${this.enigmaUrl}/registers/shifts/${shiftId}/cash`, payload);
  }

  getCashManagementShifts(
    queryParams: RegisterTypes.CashManagementShiftParam,
  ): Observable<
    RewaaPaginatedResponse<
      RegisterTypes.CashManagementShiftResponse,
      RewaaOffsetPaginatedResponse
    >
  > {
    const { offset = 0, limit = 10 } = queryParams;

    let httpParams = new HttpParams().set('limit', limit).set('offset', offset);

    Object.keys(queryParams).forEach((key) => {
      if (['limit', 'offset'].includes(key)) {
        return;
      }
      httpParams = httpParams.set(key, queryParams[key]);
    });

    return this.http.get<
      RewaaPaginatedResponse<
        RegisterTypes.CashManagementShiftResponse,
        RewaaOffsetPaginatedResponse
      >
    >(`${this.enigmaUrl}/registers/shifts/cash-management`, {
      params: httpParams,
    });
  }

  getShiftCashMovements(
    shiftId: number,
    offset = 0,
    limit = 5,
  ): Observable<
    RewaaBaseApiResponse<
      RegisterTypes.ShiftCashMovementsResponse[],
      RewaaApiMeta
    >
  > {
    return this.http.get<
      RewaaBaseApiResponse<
        RegisterTypes.ShiftCashMovementsResponse[],
        RewaaApiMeta
      >
    >(`${this.enigmaUrl}/registers/shifts/${shiftId}/cash-movements`);
  }

  getZatcaInvoicesStatus(): Observable<
    RewaaBaseApiResponse<
      ZatcaTypes.IZatcaInvoicesCountByStatusOutput,
      RewaaApiMeta
    >
  > {
    return this.http.get<
      RewaaBaseApiResponse<
        ZatcaTypes.IZatcaInvoicesCountByStatusOutput,
        RewaaApiMeta
      >
    >(`${this.enigmaUrl}/zatca/invoices-status`);
  }

  getRegisterDashboardLogs(
    stockLocationId: number,
  ): Observable<
    RewaaBaseApiResponse<RegisterTypes.DashboardRegisterLogResponse, never>
  > {
    return this.http.get<
      RewaaBaseApiResponse<RegisterTypes.DashboardRegisterLogResponse, never>
    >(
      `${this.enigmaUrl}/registers/locations/${stockLocationId}/dashboard-logs`,
    );
  }
}
