import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { selectSubscribedApps } from 'src/app/applications/selectors/app.selectors';
import { AppState } from '../../reducers';
import { APP_NAMES, TrialState } from '../constants';
import { AppChannelSubscription } from '../model/AppChannelSubscription';
import { AdvancedAccountingService } from '../../advanced-accounting/advanced-accounting.service';
import { ConfigurationName } from '../../configuration/configuration.service';

@Injectable()
export class ApplicationsHelperService {
  constructor(
    private store: Store<AppState>,
    private advanceAccountingService: AdvancedAccountingService,
  ) {}

  async isSubscribedToApp(appName: APP_NAMES): Promise<boolean> {
    const apps = await firstValueFrom(
      this.store.pipe(select(selectSubscribedApps), take(1)),
    );

    return !!apps.find(
      (app) => app.name.toLowerCase() === appName.toLowerCase(),
    );
  }

  isAccountingAppInstalled(subscribedApps: AppChannelSubscription[]): boolean {
    return !!subscribedApps.find(
      (sub) => sub.name.toLowerCase() === APP_NAMES.EXPENSES.toLowerCase(),
    );
  }

  isAdvAccountingAppInstalled(
    subscribedApps: AppChannelSubscription[],
  ): boolean {
    return !!subscribedApps.find(
      (sub) => sub.name.toLowerCase() === APP_NAMES.ACCOUNTING.toLowerCase(),
    );
  }

  isPromotionAppInstalled(subscribedApps: AppChannelSubscription[]): boolean {
    return !!subscribedApps.find(
      (sub) =>
        sub.name.toLowerCase() === APP_NAMES.PROMOTION_MANAGMENET.toLowerCase(),
    );
  }

  isPosAppInstalled(subscribedApps: AppChannelSubscription[]): boolean {
    return !!subscribedApps.find(
      (sub) => sub.name.toLowerCase() === APP_NAMES.INTERNAL_POS.toLowerCase(),
    );
  }

  checkAccountingAppStatus = async (
    subscribedApps: AppChannelSubscription[],
  ): Promise<{
    isAccountingAppInstalled: boolean;
    isAccountingAppTrial: boolean;
    isTrialNotStarted: boolean;
    isAccountingTrialEnd: boolean;
  }> => {
    const accountingApp = subscribedApps.find(
      (sub) => sub.name.toLowerCase() === APP_NAMES.ACCOUNTING.toLowerCase(),
    );

    const configuration = await firstValueFrom(
      this.advanceAccountingService.getSetting(ConfigurationName.SETUP),
    );

    const isAccountingAppInstalled = !!accountingApp;
    const isAccountingAppTrial =
      isAccountingAppInstalled && accountingApp.trialStatus !== TrialState.PAID;
    const isTrialNotStarted =
      isAccountingAppInstalled &&
      isAccountingAppTrial &&
      !configuration?.value?.bankAndOpeningBalance;
    const isAccountingTrialEnd =
      accountingApp?.trialStatus === TrialState.EXPIRED;

    return {
      isAccountingAppInstalled,
      isAccountingAppTrial,
      isTrialNotStarted,
      isAccountingTrialEnd,
    };
  };
}
